import { getUserData } from "@/auth/utils";
import axios from "@axios";
import VueI18n from "@/libs/i18n";

export default {
  namespaced: true,
  stateFactory: true,
  state: {
    analyze: [],
    formulasIngredients: [],
    hammaddeler: [],
    besinmaddeleri: [],
    formulas: [],
  },
  getters: {},
  mutations: {
    SET_ANALYZES(state, payload) {
      state.analyzes = payload;
      state.hammaddeler = payload[0].hammaddeler;
      state.besinmaddeleri = payload[0].besinmaddeleri;
    },
    SET_HAMMADDELER(state, payload) {
      state.hammaddeler = payload;
    },
    SET_BESINMADDELERI(state, payload) {
      state.besinmaddeleri = payload;
    },
    SET_FORMULAS(state, payload) {
      state.formulas = payload;
    },
    SET_FORMULAS_INGREDIENTS(state, payload) {
      state.formulasIngredients = payload;
      state.hammaddeler = payload[0].hammaddeler;
      state.formulas = payload[0].formulas;
    },
  },
  actions: {
    async fetchAnalyzes(ctx, plantid) {
      var response = await axios.get(
        "/Analysis/GetAnalysisIngredientsAndNutrients",
        {
          headers: { plantid: plantid },
        }
      );
      if (response.data.status != "OK") return null;
      ctx.commit("SET_ANALYZES", response.data.resultdata);

      return response.data.resultdata;
    },
    async calculateAnalyzes(ctx, { values, plantid }) {
      var response = await axios.put("/Analysis/CalculateValues/0", values, {
        headers: { plantid: plantid },
      });
      if (response.data.status != "OK") return null;
      ctx.commit("SET_BESINMADDELERI", response.data.resultdata);
      return true;
    },
    async fetchAnalyzesFormulasAndIngredients(ctx, plantid) {
      var response = await axios.get(
        "/Analysis/GetAnalysisFormulasAndIngredients",
        {
          headers: { plantid: plantid },
        }
      );
      if (response.data.status != "OK") return null;
      ctx.commit("SET_FORMULAS_INGREDIENTS", response.data.resultdata);
      return response.data.resultdata;
    },
    async calculateAmounts(ctx, { values, plantid }) {
      var response = await axios.put("/Analysis/CalculateAmounts/0", values, {
        headers: { plantid: plantid },
      });
      if (response.data.status != "OK") return null;
      ctx.commit("SET_HAMMADDELER", response.data.resultdata);
      return true;
    },
  },
};
